.AboutScreen {
	width: 100%;
	height: 100%;
	z-index: 100;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;


	.aboutContent {
		display: flex;
		line-height: 1;
		height: 100%;
		flex-direction: row;
		align-items: center;

		.navigation {
			margin-right: 40px;
			font-family: $fontPrimary;

			.title {
				font-size: 60px;
				margin-bottom: 60px;
			}

			.nav {
				text-align: right;
				font-size: 24px;
				position: relative;
				margin-right: 30px;

				&:after {
					content: '';
					position: absolute;
					top: 5px;
					right: -30px;
					height: calc(100% - 10px);
					width: 2px;
					background: #fff;
				}

				.singleNav {
					z-index: 10;
					margin-bottom: 30px;
					position: relative;
					cursor: pointer;

					&:after, &:before {
						cursor: pointer;
						content: '';
						position: absolute;
						border-radius: 100%;
					}

					&:after {
						background: #000;
						width: 10px;
						height: 10px;
						right: -34px;
						top: 9px;
						display: none;
					}

					&:before {
						background: #fff;
						width: 18px;
						height: 18px;	
						right: -38px;
						top: 5px;
					}

					&.active, &:hover {
						&:after {
							display: block;
						}
					}
				}
			}
		}

		.main {
			width: 510px;

			.sectionTitle {
				margin-top: 7px;
				font-size: 30px;
				margin-bottom: 30px;
				font-family: $fontPrimary;
			}

			.text {
				line-height: 1.5;

				a {
					color: #fff;
					position: relative;
					text-decoration: none;
					font-size: 14px;

					&:hover {
						text-decoration: underline;
					}
				}

				img {
					margin-top: 20px;
					width: 200px;
					height: auto;
				}
			}
		}
	}

	.title {

	}
}