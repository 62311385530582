.UiControlsMedia {
	position: absolute;
	/*width: 100%;*/
	width: 18px;
	bottom: 120px;

  &.audio {
	 right: 40px;
    .controls {
      .single {
        span {
          transform: translateX(-98%);
          left: -10px;
          right: intial;
          text-align: right;
        }

         &:hover {
            span {
              transform: translateX(-100%);
              opacity: 1;
            }
          }
      }
    }
  }


  &.video {
    left: 40px;

  }

  .title {
    color: #fff;
    transform: translateX(-13px);
  }

  .controls {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 1px;
      background: #fff;
      top: 0;
      left: 9px;
    }

    .single {
      z-index: 10;
      height: 18px;
      width: 18px;
      background: #fff;
      cursor: pointer;
      position: relative;
      margin-top: 26px;
      border-radius: 40px;

      span {
        position: absolute;
        color: #fff;
        pointer-events: none;
        width: 120px;
        right: -10px;
        transform: translateX(98%);
        font-size: 12px;
        letter-spacing: 0.03em;
        transition: all ease .2s;
        opacity: 0;
      }

      &:first-child {
        margin-top: 12px;
      }

      &:after {
      	content: '';
      	position: absolute;
      	width: 10px;
      	height: 10px;
      	background: #000;
      	left: 4px;
      	top: 4px;
      	display: block;
      	border-radius: 20px;
        opacity: 0;
        transition: opacity ease .2s;
      }

      &:hover {
        span {
          transform: translateX(100%);
          opacity: 1;
        }

        &:after {
          opacity: 1;
        }
      }

    	&.active {
    		&:after {
    			opacity: 1;
    		}
    	}
    }
  }


}