.BackToLandingBtn {
	position: absolute;
	width: 30px;
	height: 30px;
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
	transition: opacity ease .3s;
	background: #fff;
	border-radius: 40px;

	&.hide {
		opacity: 0;
	}

	&:after {
		content: '';
		background: #000;
		height: 16px;
		width: 16px;
		top: 7px;
		left: 7px;
		position: absolute;
		border-radius: 10px;
		opacity: 0;
		transition: all ease .2s;
	}

	&:before {
		content: 'back';
		position: absolute;
		color: #fff;
		left: -10px;
		top: 5px;
		transform: translateX(-90%);
		font-size: 12px;
		opacity: 0;
		transition: all ease .2s;
	}

	&:hover {
		&:after {
			opacity: 1;
		}

		&:before {
			opacity: 1;
			transform: translateX(-100%);
		}
	}
}