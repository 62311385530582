.LandingScreen {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	flex-wrap: wrap;
	color: #fff;
	align-items: center;

	.title {
		margin: 0 0 30px;
		font-size: 10vw;
		font-weight: 300;
		line-height: 1;
	}

	.manchet {
		width: 100%;
		text-align: center;
		margin-bottom: 40px;
		line-height: 1.7;
		height: 140px;
		display: flex;
		justify-content: center;

		.text {
			position: absolute;
			opacity: 0;
			transition: all ease .4s;

			&.intro {
				transform: translateY(4px);
			}

			&.loading {
				transform: translateY(-4px);
			}

			&.show {
				opacity: 1;
				transform: translateY(0);
				transition-delay: 0.2s;
			}
		}
	}

	.actionWrapper {
		display: flex;

	}

	/*.btn {
		border-radius: 8px;
		text-align: center;
		cursor: pointer;
		margin: 0 10px;
		width: 280px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: lowercase;

		&:first-child {
			background: #bf9f32;
		}

		&:nth-child(2) {
			background: #1c5575;
		}
	}*/

	.btn {
		cursor: pointer;
		text-transform: lowercase;
		position: relative;
		width: 260px;
		display: flex;

		&:hover {
			span.circle {
				&:after {
					opacity: 1;
				}
			}
		}

		span.sText {
			font-size: 12px;
			margin-right: 10px;
			margin-top: 3px;
			/*position: absolute;*/
			/*bottom: -20px;*/
			/*right: -4px;*/
			/*z-index: 100;*/
		}

		span.circle {
			width: 18px;
			height: 18px;
			background: #fff;
			display: inline-block;
			border-radius: 10px;
			transform: translateY(3px);
			position: relative;

			&:after {
				content: '';
				position: absolute;
				height: 10px;
				width: 10px;
				background: #000;
				border-radius: 10px;
				top: 4px;
				left: 4px;
				opacity: 0;
				transition: all ease .2s;
			}
		}

		$margin: 50px;

		&:first-child {
			margin-right: $margin;
			justify-content: right;

			span {
				margin-left: 10px;
			}

			&:after {
				content: '';
				position: absolute;
				height: 1px;
				background: #fff;
				width: $margin*2;
				right: 0;
				top: 11px;
				transform: translateX(100%);
				pointer-events: none;
			}
		}

		&:last-child {
			margin-left: $margin;
			
			span {
				margin-right: 10px;
			}
		}
	}
}