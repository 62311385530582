.VideoWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .loading {
    top: 50%;
    left: 50%;
    position: absolute;
    padding: 14px 30px;
    /*background: #fff;*/
    transform: translate(-50%, -50%);
    border-radius: 8px;
    line-height: 1;
    color: #fff;
  }

  .qualityPicker {
    position: absolute;
    z-index: 200;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    pointer-events: none;
    opacity: 0;
    transition: all ease .3s;

    &.show {
      opacity: 1;
      pointer-events: auto;
    }

    .close {
      position: absolute;
      top: -34px;
      right: 50%;
      transform: translateX(50%);
      cursor: pointer;
      width: 20px;
      height: 20px;

      &:after, &:before {
        position: absolute;
        top: 10px;
        right: 1px;
        content: "";
        width: 16px;
        height: 1px;
        background: #fff;
      }

      &:after {
        transform: rotateZ(45deg);
      }

      &:before {
        transform: rotateZ(-45deg);
      }
    }

    .single {
      cursor: pointer;
      padding: 4px 20px;
      margin: 8px 0;
      color: #fff;
      border: solid #fff 1px;
      border-radius: 10px;
      transition: all ease .2s;

      &:hover, &.active {
        background: #fff;
        color: #000;
      }
    }
  }

  /*&.loading {
    video {
      opacity: 0.5;
    }  
  }*/

  video {
    width: 200%;
    height: auto;
    transition: opacity .2s ease;
    opacity: 1;
    position: absolute;
  }

  /*&.cam0 {
    video {
      left: 0;
      top: 0;
    }
  }

  &.cam1 {
    video {
      left: 0;
      bottom: 0;
    }
  }

  &.cam2 {
    video {
      right: 0;
      bottom: 0;
    }
  }

  &.cam3 {
    video {
      right: 0;
      top: 0;
    }
  }*/
}