.AppScreen {
	opacity: 0;
	pointer-events: none;
	transition: opacity ease .5s, transform ease .5s;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/*transform: scale(0.97);*/

	&.active {
		opacity: 1;
		pointer-events: auto;
		transform: scale(1);
	}
}