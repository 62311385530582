.UiControlsTime {
	position: absolute;
	width: 100%;
	bottom: 0;
	height: 100px;
	display: flex;
	align-items: center;
	padding: 0 20px;
	box-sizing: border-box;

	.playPauseBtn {
		width: 100px;

		svg {
			cursor: pointer;
			margin-left: 18px;
		}
	}

	.currentVideoTime {
		color: #fff;
		width: 95px;
		text-align: center;
	}

	.settingsBtn {
		margin-right: 18px;
		width: 26px;
		height: 26px;
		cursor: pointer;
		fill: #fff;
	}

	.fullscreenBtn {
		height: 14px;
		width: 20px;
		border: 1px solid #fff;
		cursor: pointer;
	}

	input {
		&.videoSeekTrack {
			z-index: 20;
			background: #fff;
			-webkit-appearance: none;
			appearance: none;
			background: transparent;
			cursor: pointer;
			outline: none;
			padding: 10px 0;

			&::-webkit-slider-runnable-track {
				background: #fff;
				height: 1px;
			}

			&::-moz-range-track {
				background: #fff;
				height: 1px;
			}

			&::-webkit-slider-thumb {
				-webkit-appearance: none;
				-moz-appearance: none;
				-ms-appearance: none;
				appearance: none;
				height: 16px;
				width: 16px;
				background: #fff;
				border-radius: 10px;
				margin-top: -7px;
				
			}
		}
	}
}