.uicontrols {
	z-index: 10;
	/*display: none;*/
	opacity: 1;
	transition: opacity ease 0.5s;

	&.hide {
		opacity: 0;
	}

	&:nth-child(1) {
		transition-delay: 0.1s;
	}

	&:nth-child(2) {
		transition-delay: 0.2s;
	}
}